<template>
  <!--  <pre><code>{{ JSON.stringify(protocol, null, 2) }}</code></pre>-->
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Implantation de l'essai</h2>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue table label="Type d'essai" :value="protocol.type?.designation" />
    <KeyValue table
      label="Cibles">
      <template v-if="protocol.cibles && protocol.cibles.length > 0">
        <div class="tags">
          <MiniTag v-for="cible in protocol.cibles"
            :key="cible.id"
            :id="cible.id"
            :text="cible.valeur"
          />
        </div>
      </template>
      <template v-else>
        <span>Non renseigné</span>
      </template>
    </KeyValue>
    <KeyValue table label="Situation à favoriser" :value="protocol.situation_a_favoriser" />
    <KeyValue
      table
      label="Dispositif expérimental"
      :value="protocol.type_dispositif?.designation"
    />
    <KeyValue table label="Nombres de répétitions" :value="protocol.nombre_blocs" />
    <KeyValue
      table
      label="Surface minimum des microparcelles"
      :value="protocol.surface_microparcelles"
    />
    <KeyValue
      table
      label="Implantation des témoins"
      :value="protocol.implantation_temoins?.valeur"
    />
    <KeyValue table label="Secteur géographique" :value="protocol.secteur_geographique" />
    <KeyValue
      table
      label="Orientation par rapport au sens de semis"
      :value="protocol.orientation_semis"
    />
    <KeyValue table label="Type de sol" :value="protocol.type_sol" />
    <KeyValue table label="Commentaire" :value="protocol.commentaires" />
  </div>
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import Container from '@/components/layout/Container.vue'
import MiniTag from '@/components/base/MiniTag.vue'

export default {
  name: 'ProtocolSubItemExperimentImplementation',

  components: {
    MiniTag,
    KeyValue,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    protocol: {
      type: Object,
    },
  },

  data() {
  },

  methods: {
  },
}
</script>

<style scoped>
</style>
